import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Label } from "../ui/label";
import { ActionButton } from "../ui/ActionButton";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Skeleton } from "../../components/ui/skeleton.jsx";
import ReactPlayer from "react-player";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from "../ui/form.jsx";
import { Textarea } from "../ui/textarea.jsx";
import { Button } from "../ui/button.jsx";
import {
  update_description_content,
  remove_content,
} from "../../lib/supabaseProfile.js";
import MessageAlert from "../ui/message_alert.js";

const formSchema = z.object({
  description: z.string().min(1, { message: "El comentario es requerido" }),
});

export function CardMediaContent({
  description = "",
  url = "",
  type = "",
  order = 0,
  content_uuid,
  user_uuid = "",
  editMode = true,
  profile_uuid = null,
  setItems,
}) {
  const [open, setOpen] = useState(false);
  const [editar, setEditar] = useState(false);
  const [desc, setDesc] = useState("");
  const [message, setMessage] = useState("");
  const [opendelete, setOpendelete] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMedia, setIsLoadingMedia] = useState(true);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
    },
  });
  useEffect(()=>{
    setDesc(description)
  },[description]);

  // console.log(message)
  // useEffect(() => {
  //   if (message.length > 0) {
  //     setOpenMessage(true);
  //   }
  // }, [message]);

  const handleClick = (e) => {};

  const formatUrl = (url) => {
    let URL = url;
    console.log(URL);
    const parse_url = URL.split("/");
    console.log("parse_url",parse_url);
    if (parse_url[parse_url.length - 1].includes("watch?v=")) {
      parse_url[parse_url.length - 1] = parse_url[parse_url.length - 1].replace("watch?v=", "embed/");
    } else {
      parse_url.push(parse_url[parse_url.length - 1]);
      parse_url[parse_url.length - 1] = "embed";
    }
    URL = parse_url.join("/");
    return URL;
  };

  const renderMedia = () => {
    if (type === "image") {
      return (
        <img
          src={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${url}`}
          alt={description}
          onLoad={() => setIsLoading(false)}
          className="w-full h-full object-cover cursor-pointer"
          onClick={() => setOpen(true)}
          style={{ display: isLoading ? "none" : "block" }}
        />
      );
    } else if (type === "video") {
      return (
        <div className="relative w-full h-full object-cover">
          <ReactPlayer 
            onLoadedData={() => setIsLoading(false)}
            onClick={() => setOpen(true)}
            className="w-full h-full object-cover cursor-pointer"
            url={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${url}`}
            controls={true}
            width="100%"
            height="100%"
            //onReady={() => setIsLoadingMedia(false)}
            style={{
              objectFit: "cover",
              maxWidth: "100%",
              maxHeight:"100%",
              height:"100%",
              width:"100%",
              display: isLoading ? "none" : "block",
            }}
          />
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={handleClick}
          ></div>
        </div>
      );
    } else if (type === "link") {
      return (
        <div className="relative w-full h-full object-cover">
          <iframe
            width="100%"
            height="100%"
            src={formatUrl(url)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Video"
            onLoad={() => setIsLoading(false)}
            style={{ display: isLoading ? "none" : "block" }}
          ></iframe>
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={handleClick}
          ></div>
        </div>
      );
    } else {
      return <div>Unsupported media type</div>;
    }
  };

  const onSubmit = async () => {
    const response = await update_description_content(
      content_uuid,
      form.getValues().description,
      user_uuid
    );
    if (!response.success) {
      setMessage("Error al actualizar la descripción");
      setOpenMessage(true);
      setEditar(false);
    } else {
      setItems((items) => {
        return items.map((item) => {
          if (item.content_uuid === content_uuid) {
            return { ...item, description: form.getValues().description };
          }
          return item;
        });
      });
      setDesc(form.getValues().description);
      setMessage("Descripción actualizada correctamente");
      setOpenMessage(true);
      setEditar(false);
    }
  };

  const handleButtonClick = () => {
    setOpenMessage(false);
    setMessage("");
  };

  const handleButtonClickDelete = () => {
    setOpendelete(false);
    setItems((items) =>
      items.filter((item) => item.content_uuid !== content_uuid)
    );
    setMessage("");
  };

  const deleteContent = async () => {
    const response = await remove_content(content_uuid, user_uuid, url);
    setOpen(false);
    setIsLoading(true);
    if (response.status_code !== 200) {
      setMessage("Error al eliminar la publicación");
      setOpenMessage(true);
    } else {
      setMessage("La publicación se eliminó correctamente");
      setOpendelete(true);
      setOpen(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={setOpen}
        className="w-full h-64 bg-white shadow-md rounded-lg overflow-hidden"
      >
        <DialogTrigger asChild>
          <div
            className="w-full h-full aspect-square"
            onClick={() => {
              setEditar(false);
            }}
          >
            {isLoading && <Skeleton className="w-full h-full" />}
            {renderMedia()}
          </div>
        </DialogTrigger>
        <DialogContent className="max-w-[90%] sm:max-w-[425px]">
          <div className="grid gap-4 py-4">
            {isLoadingMedia && <Skeleton className="w-full h-64" />}
            {url.toLowerCase().endsWith(".mp4") ? (
              <video
                controls
                className="mb-4 w-full h-64 object-contain mx-auto block"
                onLoadedData={() => setIsLoadingMedia(false)}
                style={{
                  maxWidth: "100%",
                  display: isLoadingMedia ? "none" : "block",
                }}
              >
                <source
                  src={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${url}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : url.toLowerCase().endsWith(".mov") ? (
              <ReactPlayer
                url={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${url}`}
                controls={true}
                width="100%"
                height="13rem"
                onReady={() => setIsLoadingMedia(false)}
                style={{
                  maxWidth: "100%",
                  display: isLoadingMedia ? "none" : "block",
                }}
              />
            ) : url.includes("youtube.com") ? (
              <iframe
                width="100%"
                height="100%"
                onLoad={() => setIsLoadingMedia(false)}
                style={{
                  maxWidth: "100%",
                  display: isLoadingMedia ? "none" : "block",
                }}
                className="mb-4 w-full h-64 object-contain mx-auto block"
                src={formatUrl(url)}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
              ></iframe>
            ) : (
              <img
                src={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${url}`}
                alt="Media"
                className="mb-4 h-64 object-contain mx-auto block"
                style={{
                  maxWidth: "100%",
                  maxHeight: "70vh",
                  display: isLoadingMedia ? "none" : "block",
                }}
                onLoad={() => setIsLoadingMedia(false)}
              />
            )}
            <div className="flex justify-between items-center">
              <Label className="ml-2 pt-11">Descripción:</Label>
              <div className="flex flex-col items-end">
                <Button
                  className={`${
                    editMode ? "" : "hidden"
                  } bg-white text-red-500 font-semibold h-7 hover:bg-black hover:text-white border border-red-500 py-0 px-4 rounded hover:border-black mb-2`}
                  onClick={deleteContent}
                  style={{ width: "fit-content" }}
                >
                  Eliminar
                </Button>
                <ActionButton
                  label="editar descripción"
                  onClick={() => {
                    form.setValue("description", desc ? desc : "");
                    setEditar(true);
                  }}
                  iconClass="fas fa-pencil mr-2"
                  className={`${
                    editMode ? "" : "hidden"
                  } bg-transparent h-7 hover:bg-black text-black font-semibold text-xs hover:text-white py-0 px-4 border border-black hover:border-transparent rounded`}
                />
              </div>
            </div>
            {editar && (
              <div>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4"
                  >
                    <div className="grid grid-row-1 gap-4">
                      <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-base">
                              Descripción: *
                            </FormLabel>
                            <FormControl>
                              <Textarea
                                placeholder="Descripción *"
                                {...field}
                                className="w-full px-4 py-3 mb-0 h-28 font-medium bg-white text-black text-base focus:outline-none focus:bg-white border border-gray-300 resize-none"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="flex justify-around">
                        <Button
                          type="submit"
                          className="bg-red_1 hover:bg-red-700 m-0 text-white font-bold py-2 px-4 rounded"
                        >
                          Guardar
                        </Button>
                        <Button
                          onClick={() => {
                            form.reset({ description: "" });
                            setEditar(false);
                          }}
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                        >
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </form>
                </Form>
              </div>
            )}
            {!editar && (
              <p className="whitespace-pre-wrap ml-2">
                {!desc || desc.length === 0 ? "No hay descripción" : desc}
              </p>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {message.length > 0 && (
        <MessageAlert
          open={openMessage}
          setOpen={setOpenMessage}
          message={message}
          messageContinue="Aceptar"
          actionContinue={handleButtonClick}
        />
      )}
      <MessageAlert
        open={opendelete}
        setOpen={setOpendelete}
        message={message}
        messageContinue="Aceptar"
        actionContinue={handleButtonClickDelete}
      />
    </>
  );
}

export default CardMediaContent;
