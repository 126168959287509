import React, { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useAuth } from "../../contexts/AuthContext";

import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from "../ui/form";

import { Combobox } from "../ui/combobox";

import { Input } from "../ui/input";

import { Textarea } from "../ui/textarea";

import { Loading } from "../ui/loading";

import { MessageAlert } from "../ui/message_alert";

import {
  get_relationship_states_countries,
  get_states_by_countries,
  post_profile,
} from "../../lib/supabaseProfile";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

const formSchema = z
  .object({
    first_name: z.string().min(1, { message: "El nombre es requerido" }),
    second_name: z.string(),
    last_name: z.string().min(1, { message: "El nombre es requerido" }),
    relationship_id: z
      .number()
      .int()
      .min(1, { message: "La relación es requerida" }),
    country: z.number().int().min(1, { message: "El país es requerido" }),
    state_id: z.number().int().min(1, { message: "El estado es requerido" }),
    date_birth: z.string().refine((dateStr) => !isNaN(Date.parse(dateStr)), {
      message: "Ingrese una fecha válida",
    }),
    date_departure: z
      .string().optional(),
    phrase: z.string(),
    biography: z.string(),
    is_private: z.boolean(),
    password: z.string().optional(),
    re_pass: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.is_private && (!data.password || data.password.length !== 4)) {
        return false;
      }
      return true;
    },
    {
      path: ["password"], // Define el campo que muestra el mensaje de error
      message: "La contraseña debe ser de 4 digitos", // Mensaje de error
    }
  );

function CreateProfile() {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [data, setData] = useState({
    relationships: [],
    countries: [],
    states: [],
  });

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const response = await get_relationship_states_countries();
      if (response.success) {
        setData((prevData) => ({ ...prevData, ...response.data, states: [] }));
      }
      setLoading(false);
    };

    getData();
  }, []);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      second_name: "",
      last_name: "",
      relationship_id: 0,
      country: 0,
      state_id: 0,
      date_birth: "",
      date_departure: "",
      phrase: "",
      biography: "",
      is_private: false,
      password: "",
      re_pass: "",
    },
  });

  const handleTabChange = (value) => {
    setMessageError("");
    form.setValue("is_private", value === "si" ? true : false);
  };

  const country = form.watch("country");

  useEffect(() => {
    const getStates = async () => {
      if (country) {
        const response = await get_states_by_countries(country);
        if (response.success) {
          setData((prevData) => ({ ...prevData, states: response.data }));
        }
      }
      setLoading(false);
    };

    getStates();
  }, [country]);

  // Loagin reset change
  if (loading) {
    return <Loading />;
  }

  const onSubmit = async (data) => {
    console.log(data);
    if(data.is_private && (data.re_pass.length!==4 || data.re_pass !== data.password)){
      setMessageError("La confirmaci√≥n de la contrase√±a debe coincidir y tener 4 dígitos.");
    }
    const response = await post_profile(data, profile.user_uuid);

    if (response.success) {
      setMessage("Perfil creado correctamente");
      setOpen(true);
    } else {
      setMessage("Error al crear el perfil");
      setOpen(true);
      console.log(response.data);
    }
  };

  const handleButtonClick = () => {
    navigate("/dashboard");
  };

  return (
    <div className="w-full flex flex-col items-center px-0 mt-14 md:mt-28 mb-20">
      <div className="w-full max-w-5xl bg-white md:shadow-lg relative  text-center md:text-left md:rounded-lg px-0 sm:px-10">
        <div className="container mx-auto">
          <Button
            onClick={handleButtonClick}
            className="px-4 py-2 bg-red_1 text-white text-base rounded data-[state=active]:hidden transition duration-300 ease-in-out transform hover:scale-110 mt-4 ml-3"
          >
            Volver
          </Button>

          <div className="bg-white px-4 py-6 md:w-full md:max-full md:mx-full">
            <h1 className="text-lg font-semibold text-left">
              Detalles Personales
            </h1>
            <p className="text-sm text-left mt-2 mb-4">
              {" "}
              Tendrás la oportunidad de editarlo una vez publicado.
            </p>
            <p className="text-sm text-red_1 text-left mt-2 mb-4">
              {" "}
              Los campos que tienen asterisco(*) son obligatorios.
            </p>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">
                          Primer Nombre: *
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Primer Nombre *"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="second_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">
                          Segundo Nombre:
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Segundo Nombre"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Apellido: *</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Apellido *"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="relationship_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Relación: *</FormLabel>
                        <FormControl>
                          <Combobox
                            label="Relación"
                            data={data.relationships}
                            valueField="relationship_id"
                            keyField="description"
                            value={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="date_birth"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">
                          Fecha de nacimiento: *
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder="dd/mm/aaaa"
                            type="date"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="date_departure"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">
                          Fecha de defunción:
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder="dd/mm/aaaa"
                            type="date"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="country"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">País: *</FormLabel>
                        <FormControl>
                          <Combobox
                            label="País"
                            data={data.countries}
                            valueField="country_id"
                            keyField="name_es"
                            value={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="state_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Región: *</FormLabel>
                        <FormControl>
                          <Combobox
                            label="Región"
                            data={data.states}
                            valueField="state_id"
                            keyField="name"
                            value={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                          ></Combobox>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="phrase"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base">Frase:</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Frase"
                          {...field}
                          className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="biography"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base">Biografía:</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Biografía"
                          {...field}
                          className="w-full px-4 py-3 mb-1.5 h-28 font-medium bg-white text-black text-base focus:outline-none focus:bg-white border border-gray-300 resize-none"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <Tabs
                  defaultValue="no"
                  className="w-full"
                  onValueChange={handleTabChange}
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="no">Perfil Público</TabsTrigger>
                    <TabsTrigger value="si">Perfil Privado</TabsTrigger>
                  </TabsList>
                  <TabsContent value="no"></TabsContent>
                  <TabsContent
                    value="si"
                    className="flex flex-col justify-between w-full h-full"
                  >
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel className="text-base">
                            Contraseña:
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="contraseña"
                              type="number"
                              style={{
                                MozAppearance: "textfield", // Firefox
                              }}
                              maxLength={4}
                              onWheel={(e) => e.target.blur()}
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="re_pass"
                      render={({ field }) => (
                        <FormItem className="mt-2">
                          <FormLabel className="text-base">
                            Verificar Contraseña:
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="verificar contraseña"
                              type="number"
                              style={{
                                MozAppearance: "textfield", // Firefox
                              }}
                              maxLength={4}
                              onWheel={(e) => e.target.blur()}
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          {/*<FormMessage />*/}
                          <div className="flex items-center text-sm justify-between mt-1.5 mb-0 text-red_1">
                            {messageError}
                          </div>
                        </FormItem>
                      )}
                    />
                  </TabsContent>
                </Tabs>
                {/*<FormField
                  control={form.control}
                  name="is_private"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center ">
                      <FormLabel className="block mt-2 text-lg font-medium text-gray-700">
                        Profile Privado
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className="ml-3 flex flex-row items-center space-y-0"
                        >
                          <FormItem className="flex items-center justify-center space-x-3 space-y-0 m-0">
                            <FormControl>
                              <RadioGroupItem value="si" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Perfil
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center justify-center space-x-3 space-y-0 mt-0">
                            <FormControl>
                              <RadioGroupItem value="no" />
                            </FormControl>
                            <FormLabel className="font-normal">No</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />*/}

                <h2 className="text-lg font-semibold text-left">
                  ¿Cómo subir las fotos y videos?
                </h2>
                <p className="text-sm text-left mb-4">
                  Una vez guardes este perfil, en tu pantalla principal verás el
                  perfil creado con un botón de "editar". Una vez ingreses,
                  podrás subir las fotos y los videos de tus seres queridos.
                </p>
                <Button
                  type="submit"
                  className="bg-red_1 hover:bg-red-700 mt-2 text-white font-bold py-2 px-4 rounded"
                >
                  Crear Nuevo Perfil
                </Button>
              </form>
            </Form>
            <MessageAlert
              open={open}
              setOpen={setOpen}
              message={message}
              messageContinue="Aceptar"
              actionContinue={handleButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProfile;
