import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from "../ui/form";
import { Combobox } from "../ui/combobox";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Loading } from "../ui/loading";
import { MessageAlert } from "../ui/message_alert";
import {
  get_relationship_states_countries,
  get_states_by_countries,
  hashear_password,
  update_profile,
} from "../../lib/supabaseProfile";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

function UpdateProfile({ profile_uuid, form, user_uuid }) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    relationships: [],
    countries: [],
    states: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_relationship_states_countries(user_uuid);
      // console.log("-", response);
      if (response.success) {
        setData((prevData) => ({ ...prevData, ...response.data, states: [] }));
      }
      setLoading(false);
    };
    fetchData();
  }, [user_uuid]);

  const country_id = form.watch("country_id");
  // console.log(form.watch("state_id"));
  useEffect(() => {
    const getStates = async () => {
      setLoading(true);
      if (form.watch("country_id") && data.countries.length > 0) {
        const response = await get_states_by_countries(
          form.watch("country_id"),
          user_uuid
        );
        if (response.success) {
          setData((prevData) => ({ ...prevData, states: response.data }));
        }
      }
      setLoading(false);
    };
    getStates();
  }, [form, country_id, user_uuid,data.countries]);

  const handleTabChange = (value) => {
    form.setValue("is_private", value === "si" ? true : false);
  };

  const onSubmit = async (data) => {
    if(data.is_private){
      data.password = hashear_password(data);
    }
    const response = await update_profile(data, profile_uuid, user_uuid);
    if (response.success) {
      setMessage("Perfil actualizado correctamente.");
      setOpen(true);
    } else {
      setMessage("Error al actualizar el perfil.");
      setOpen(true);
    }
  };

  const handleCountryChange = (val) => {
    form.setValue("country_id", val);
    form.setValue("state_id", 0);
  };

  return (
    <>
      <div className="w-full flex flex-col items-center ">
        <div className="w-full max-w-full md:max-w-5xl bg-white relative text-center md:text-left md:rounded-lg px-0 md:px-10">
          <div className="md:container md:mx-auto">
            <div className="bg-white px-4 py-6 w-full max-full mx-full">
              <h1 className="text-lg font-semibold text-left">
                Detalles Personales
              </h1>
              <p className="text-sm text-red_1 text-left mt-2 mb-4">
                {" "}
                Los campos que tienen asterisco(*) son obligatorios.
              </p>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-4"
                >
                  <div className="w-full max-w-4xl flex flex-col md:grid md:grid-cols-2 gap-2">
                    <FormField
                      control={form.control}
                      name="first_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base w-full">
                            Primer Nombre: *
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Primer Nombre *"
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="second_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">
                            Segundo Nombre:
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Segundo Nombre"
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="last_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">
                            Apellido: *
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Apellido *"
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="relationship_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">
                            Relación: *
                          </FormLabel>
                          <FormControl>
                            <Combobox
                              label="Relación"
                              data={data.relationships}
                              valueField="relationship_id"
                              keyField="description"
                              value={field.value}
                              onChange={(val) => {
                                field.onChange(val);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="date_birth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">
                            Fecha de nacimiento: *
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="dd/mm/aaaa"
                              type="date"
                              {...field}
                              className="flex flex-col justify-evenly w-full max-w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="date_departure"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">
                            Fecha de defunción:
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="dd/mm/aaaa"
                              type="date"
                              {...field}
                              className="flex flex-col justify-evenly w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="country_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">País: *</FormLabel>
                          <FormControl>
                            <Combobox
                              label="País"
                              data={data.countries}
                              valueField="country_id"
                              keyField="name_es"
                              value={field.value}
                              onChange={handleCountryChange}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="state_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">Región: *</FormLabel>
                          <FormControl>
                            <Combobox
                              label="Región"
                              data={data.states}
                              valueField="state_id"
                              keyField="name"
                              value={field.value}
                              onChange={(val) => {
                                field.onChange(val);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="phrase"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Frase:</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Frase"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="biography"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Biografía:</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Biografía"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-28 font-medium bg-white text-black text-base focus:outline-none focus:bg-white border border-gray-300 resize-none"
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <Tabs
                  defaultValue={form.watch("is_private") === true ? "si" : "no"}
                  className="w-full "
                  onValueChange={handleTabChange}
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="no">Perfil Público</TabsTrigger>
                    <TabsTrigger value="si">Perfil Privado</TabsTrigger>
                  </TabsList>
                  <TabsContent value="no"></TabsContent>
                  <TabsContent
                    value="si"
                    className="flex flex-col justify-between w-full h-full"
                  >
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel className="text-base">
                            Contraseña:
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="contraseña"
                              type="number"
                              style={{
                                MozAppearance: "textfield", // Firefox
                              }}
                              maxLength={4}
                              onWheel={(e) => e.target.blur()}
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {/*<FormField
                      control={form.control}
                      name="re_pass"
                      render={({ field }) => (
                        <FormItem className="mt-2">
                          <FormLabel className="text-base">
                            Verificar Contraseña:
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder="verificar contraseña"
                              type="number"
                              style={{
                                MozAppearance: "textfield", // Firefox
                              }}
                              maxLength={4}
                              onWheel={(e) => e.target.blur()}
                              {...field}
                              className="w-full px-4 py-3 mb-1.5 h-12 font-medium text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />*/}
                  </TabsContent>
                </Tabs>
                  <Button
                    type="submit"
                    className="bg-red_1 hover:bg-red-700 mt-2 text-white font-bold py-2 px-4 rounded"
                  >
                    Guardar Perfil
                  </Button>
                </form>
              </Form>
              <MessageAlert
                open={open}
                setOpen={setOpen}
                message={message}
                messageContinue="Aceptar"
              />
            </div>
          </div>
        </div>
      </div>
      <Loading loading={loading} />
    </>
  );
}

export default UpdateProfile;
